/** @format */

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700&display=swap');
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #000000;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    
}

.col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px 15px;
}

.col-md-2 {
    flex: 0 0 16.67;
    max-width: 16.67%;
}

.col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container-fluid2 {
    width: 100%;
    padding-right: 80px;
    padding-left: 80px;
    margin-right: auto;
    margin-left: auto;
}


/* common button */

.banner_button a {
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    transform: skew(-25deg);
    background: rgb(80, 187, 202);
    background: linear-gradient(90deg, rgba(80, 187, 202, 1) 0%, rgba(238, 120, 12, 1) 100%);
    min-width: 234px;
    font-size: 19px;
    text-align: center;
    /* font-weight: 700; */
    text-decoration: none;
    color: white;
    letter-spacing: 1px;
    transition: 0.5s ease-in;
    padding: 7px 0px;
    -webkit-transition: 0.5s ease-in;
    -moz-transition: 0.5s ease-in;
    -ms-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
}

.banner_button a span {
    /* display: inline-block;
    transform: skew(25deg); */
}

.banner_button a:nth-child(2) {
    border: 1px solid white;
    color: white;
    background: transparent;
}

.gradient-text {
    background: rgb(253, 187, 45);
    background: linear-gradient(0deg, rgba(253, 187, 45, 1) 0%, rgba(170, 189, 102, 1) 25%, rgba(34, 193, 195, 1) 100%);
    -webkit-background-clip: text;
    color: transparent;
}

.banner_button a:hover {
    transform: skew(-25deg) translateY(-10px)!important;
    -webkit-transform: skew(-25deg) translateY(-10px)!important;
    -moz-transform: skew(-25deg) translateY(-10px)!important;
    -ms-transform: skew(-25deg) translateY(-10px)!important;
    -o-transform: skew(-25deg) translateY(-10px)!important;
}

.gradient {
    background: rgb(38, 191, 237);
    background: linear-gradient(90deg, rgba(38, 191, 237, 1) 0%, rgba(131, 0, 207, 1) 51%, rgba(239, 119, 13, 1) 80%);
    -webkit-background-clip: text;
    color: transparent;
}

.gradient2 {
    background: rgb(38, 191, 237);
    background: linear-gradient(90deg, rgba(38, 191, 237, 1) 0%, rgba(175, 0, 189, 1) 39%, rgba(240, 103, 16, 1) 80%);
    -webkit-background-clip: text;
    color: transparent !important;
}

.common_title {
    font-size: 66px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
}

.common_title span {
    justify-content: center;
    margin: 0 0 -10px;
    padding: 0;
    /* text-shadow: 2px 2px 50px #50dbff; */
}

.common_title span:first-child {
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}

.common_title span:nth-child(2) {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}

.common_title span:nth-child(3) {
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}

.common_title span:nth-child(4) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.common_title span:nth-child(5) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.common_title span:nth-child(6) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}

.common_title span:nth-child(7) {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

.common_title span:nth-child(8) {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

.common_title span:nth-child(9) {
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}

.common_title span:nth-child(10) {
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
}

.common_title span {
    -webkit-animation: ani 1.2s linear infinite;
    animation: ani 1.2s linear infinite;
}

@keyframes ani {
    0% {
        text-shadow: 1px 1px 5px rgba(38, 191, 237, 1);
    }
    50% {
        text-shadow: 1px 1px 5px rgba(131, 0, 207, 1);
    }
    100% {
        text-shadow: 0 0 7px rgba(239, 119, 13, 1), 0 0 20px rgba(239, 119, 13, 1);
    }
    /* You can also define the colors using the linear gradient */
}

.gradient3 {
    background: rgb(38, 191, 237);
    background: linear-gradient(90deg, rgba(38, 191, 237, 1) 0%, rgba(175, 0, 189, 1) 39%, rgba(240, 103, 16, 1) 65%);
    -webkit-background-clip: text;
    color: transparent !important;
}

@media (max-width:1192px) {
    .common_title {
        font-size: 50px;
    }
    .banner_button a {
        font-size: 15px;
    }
    .container-fluid2 {
        padding-right: 40px;
        padding-left: 40px;
    }
}

@media (max-width:767.98px) {
    .common_title {
        font-size: 45px;
    }
}

@media (max-width:575.98px) {
    .common_title {
        font-size: 27px;
    }
}

.blink-soft {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media (max-width:575.98px) {
    .newSkewButton {
        position: relative;
        left: -20px;
    }
}